





























import { defineComponent, ref, computed, watch } from '@vue/composition-api'
import { Auth } from 'aws-amplify'
import { framebus } from 'plugins'
import { frameBusEvent } from 'utils'

const Start = defineComponent({
  props: {
    iFramePath: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const isCloseMode = ref(false)
    const numberOfIframes = ref(0)
    const currentNumberIframes = ref(0)
    const homeUrl = ref('')
    const styleHidden = { display: 'none' }
    const WIDTH_OF_PC = 1200
    const WIDTH_OF_LAND_IPAD = 768
    const listId = ref([0])
    const isShowRightNavBar = ref(false)
    const inIframe = (): boolean => {
      try {
        return window.self !== window.top
      } catch (e) {
        return true
      }
    }

    const getClass = (iframe: number, sumIframe: number) => {
      let class1 = ''
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && window.innerWidth < WIDTH_OF_PC) {
        class1 = 'heightTablet'
      } else if (window.innerWidth >= WIDTH_OF_PC) {
        class1 = 'heightPc'
      } else {
        class1 = 'heightMobile'
      }
      let class2 = ''
      if (sumIframe === 0) class2 = 'oneShow'
      if (sumIframe === 1) class2 = 'twoShow'
      if (sumIframe === 2) class2 = 'threeShow'
      if (sumIframe === 3) class2 = 'fourShow'
      return `${class1} ${class2}`
    }
    homeUrl.value = process.env.VUE_APP_WEB_URL
      ? `${process.env.VUE_APP_WEB_URL}/home?called=true`
      : 'http://localhost:4000/home'
    const maxIframe = ref(0)

    const getWindowWidth = () => {
      // for PC
      if (window.innerWidth >= WIDTH_OF_PC) {
        maxIframe.value = 3
      }
      // for landscape Ipad
      else if (window.innerWidth >= WIDTH_OF_LAND_IPAD && window.innerWidth < WIDTH_OF_PC) {
        maxIframe.value = 1
      } else {
        maxIframe.value = 0
      }
    }
    getWindowWidth()

    window.addEventListener('resize', () => {
      getWindowWidth()
    })

    const getHomePage = () => {
      numberOfIframes.value += 1
      listId.value.push(numberOfIframes.value)
      currentNumberIframes.value += 1
      homeUrl.value = process.env.VUE_APP_WEB_URL
        ? `${process.env.VUE_APP_WEB_URL}/home?called=true`
        : 'http://localhost:4000/home'
      setTimeout(() => {
        framebus.emit(frameBusEvent.ADD_FRAME)
      }, 1200)
    }
    const closeIframe = (index: number) => {
      document.getElementById(listId.value[index].toString())?.remove()
      listId.value.splice(index, 1)
      currentNumberIframes.value -= 1
    }
    // path for 1st frame
    const pathInIframe = computed(() => {
      if (props.iFramePath?.indexOf('?') !== -1) {
        return `${props.iFramePath}&called=true`
      }
      return `${props.iFramePath}?called=true`
    })

    window.logout = (): void => {
      Auth.signOut().then(() => {
        console.log('logout')
      })
    }
    watch(
      () => currentNumberIframes.value,
      () => {
        if (currentNumberIframes.value === 0) isCloseMode.value = false
      }
    )
    return {
      numberOfIframes,
      maxIframe,
      getHomePage,
      homeUrl,
      pathInIframe,
      styleHidden,
      getClass,
      inIframe,
      isCloseMode,
      closeIframe,
      currentNumberIframes,
      isShowRightNavBar
    }
  }
})
export default Start
